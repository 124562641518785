body {
  color: white;
  margin: 0;
  background: url("../assets/bg.jpg") no-repeat center center fixed;
  background-size: cover;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

hanko-auth::part(form-item) {
  min-width: 100%; /* input fields and buttons are on top of each other  */
}


.container {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

/* .column {
  flex: 1;
  min-width: 0;
  padding: 1rem;
} */

/*
marin-top set to 10px
*/
.column {
  padding: 24px;
  border-radius: 17px;
  /* width: 100%; */
  /* max-width: 450px; */
  /* min-width: 330px; */
  margin: 10vh auto;
  /* margin-top: 0px; */
  z-index: 4;
}


.content {
  padding: 0px;
  border-radius: 17px;
  /* color: black; */
  /* background-color: white; */
  /* width: 100%; */
  max-width: 450px;
  min-width: 330px;
  margin: 10vh auto;
  margin-top: -40px;
  z-index: 4;
}


@media (min-width: 768px) {
  .row {
    flex-direction: row;
    overflow-x: hidden;
    overflow-y: hidden;
    white-space: nowrap;
  }

  .column {
    flex: 1;
    min-width: 0;
    padding: 1rem;
    justify-content: center;
    align-items: center;
  }
}