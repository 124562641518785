.app-container {
  display: flex;
  flex-direction: column;
  min-height: 130vh;
  z-index: 3;
}  

.logo-footer {
  z-index: 1;
}

.gradient-text {
  font-weight: bold;
  background: linear-gradient(to right, #0ACF83, #A259FF, #F24E1E, #FF7262);
  -webkit-background-clip: text;
  color: transparent;
}