.logo-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 20px; /* 距离底部20px */
  left: 0;
  right: 0;
  opacity: 1.0;
  z-index: 10;
}

.logo-image {
  width: 45px;
  height: 45px;
  margin-right: 5px; /* 图片和文字之间保持10px 水平间距 */
}

.text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 5px; /* 文字距离图片保持5px 水平间距 */
}

.charming-text {
  font-size: 18px;
  font-weight: 900;
  margin: 0;
}

.mos-text {
  font-size: 16px;
  font-weight: 900;
  margin: 0;
}