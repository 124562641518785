.nav {
  width: 100%;
  padding: 10px;
  opacity: 1.0;
  z-index: 4;
}

.button {
  font-size: 1rem;
  border: none;
  background: none;
  cursor: pointer;
}

.button:disabled {
  color: grey!important;
  cursor: default;
  text-decoration: none!important;
}

.nav .button:hover {
  text-decoration: underline;
}

.nav .button {
  color: white;
  float: right;
}

.content {
  padding: 24px;
  border-radius: 17px;
  color: black;
  background-color: white;
  width: 100%;
  max-width: 500px;
  min-width: 330px;
  margin: 10vh auto;
  z-index: 4;
}

.headline {
  text-align: center;
  margin-top: 0;
}

.form {
  display: flex;
  margin-bottom: 17px;
}

.form .input {
  flex-grow: 1;
  margin-right: 10px;
}

.form .button {
  color: black;
}

.list {
  display: flex;
  flex-direction: column;
  row-gap: 7px;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 7px;
}

.description {
  flex-grow: 1;
  cursor: pointer;
}

.error {
  color: red;
  padding: 0 0 10px;
}

.input {
  border: 1px solid black;
  border-radius: 2.4px;
}

.checkbox {
  margin-left: 0;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  font: inherit;
  color: currentColor;
  width: 1em;
  height: 1em;
  border: 1px solid currentColor;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

.checkbox::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  box-shadow: inset 1em 1em black;

  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

.checkbox:checked::before {
  transform: scale(1);
}
